import { webServiceApi } from '../service';
export default {
  // 获取用户信息
  getUserInfo(params) {
    return webServiceApi.post('/index.php/User/get_info', params);
  },
  // 修改密码
  setPwd(params) {
    return webServiceApi.post('/index.php/User/set_pwd', params);
  },

  // get请求示例
  getExample(params) {
    return webServiceApi.get('/xxxx/xxx', { params });
  },
};
