<template>
  <div class="right-con">
    <div class="right-title">
      <span>账户设置</span>
    </div>

    <div class="con-item">
      <span>用户：</span>
      <span class="item-center">{{ userSet.userEmail }}</span>
      <!--            <span class="update">更改</span>-->
    </div>
    <div class="con-item">
      <span>邮箱：</span>
      <span class="item-center">{{ userSet.userEmail }}</span>
      <!--            <span class="update">更改</span>-->
    </div>
    <div class="con-item">
      <span>密码：</span>
      <span class="item-center">已设置，可通过邮箱+密码登录</span>
      <span class="update" @click="showModal">更改</span>
      <a-modal
        title="更换密码"
        v-model:visible="visible"
        style="color: #222222"
        :footer="null"
        width="400px"
      >
        <a-form layout="inline" ref="formRef" :rules="rules" :model="formState">
          <div class="label">新密码</div>
          <a-form-item class="passwd" name="password">
            <a-input
              v-model:value="formState.password"
              type="password"
              autocomplete="off"
              placeholder="请输入新密码"
            />
          </a-form-item>

          <div class="label">确认密码</div>
          <a-form-item class="passwd" name="password2">
            <a-input
              v-model:value="formState.password2"
              type="password"
              autocomplete="off"
              placeholder="请再次输入新密码"
            />
          </a-form-item>

          <a-form-item class="btn">
            <a-button @click="saveClick" :class="{ onedit: btnActive }"
              >保存</a-button
            >
          </a-form-item>
          <a-form-item class="btn">
            <a-button @click="cancelClick" class="onedit">取消</a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import router from '@/routes';
import { message } from 'ant-design-vue';
import store from '@/vuex/store';
import api from '@/api/account-setting';
export default defineComponent({
  name: 'AccountSet',
  components: {},
  setup() {
    let userId = store.state.user.userinfo;
    const btnActive = ref(false);
    const formRef = ref();
    const userSet = reactive({
      userEmail: '',
    });
    const formState = reactive({
      password: '',
      password2: '',
    });
    const validateEdit = async (rule, value) => {
      if (value === '') {
        btnActive.value = false;
      } else {
        btnActive.value = true;
      }
      return Promise.resolve();
    };
    const validatePass = async () => {
      if (formState.password2 !== '') {
        formRef.value.validateFields('password2');
      }
      return Promise.resolve();
    };
    const validatePass2 = async (rule, value) => {
      if (value !== formState.password) {
        return Promise.reject('请输入相同密码');
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      password: [
        { required: true, message: '输入的密码不能为空', trigger: 'blur' },
        { required: true, message: '输入的密码不能为空', trigger: 'change' },
        { validator: validatePass, trigger: 'blur' },
        { validator: validatePass, trigger: 'change' },
        { validator: validateEdit, trigger: 'change' },
      ],
      password2: [
        { required: true, message: '输入的密码不能为空', trigger: 'blur' },
        { required: true, message: '输入的密码不能为空', trigger: 'change' },
        { validator: validatePass2, trigger: 'blur' },
        { validator: validatePass2, trigger: 'change' },
        { validator: validateEdit, trigger: 'change' },
      ],
    };
    const getUserMessage = async () => {
      const params = {
        user_id: userId.uid,
      };
      const res = await api.getUserInfo(params);
      if (!res) return;
      try {
        const { data, code } = res;
        if (code == 0) {
          userSet.userEmail = data.email;
        } else {
          message.error('获取用户信息失败');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const backClick = () => {
      router.push({
        path: '/mainhome/simplifyhome/pat',
      });
    };
    let saveClick = () => {
      formRef.value
        .validate()
        .then(async () => {
          const params = {
            user_id: userId.uid,
            password: formState.password,
          };
          const res = await api.setPwd(params);
          if (!res) return;
          try {
            const { code } = res;
            if (code == 0) {
              visible.value = false;
              message.success('更改密码成功！');
            } else {
              message.error('更改密码失败');
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    const cancelClick = () => {
      visible.value = false;
      // console.log(formRef.value)
    };

    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    onMounted(() => {
      getUserMessage();
    });
    return {
      btnActive,
      visible,
      showModal,
      formRef,
      formState,
      rules,
      cancelClick,
      saveClick,
      backClick,
      userSet,
    };
  },
});
</script>

<style scoped>
.right-con {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 40px;
  background-color: #ffffff;
}
.right-title {
  height: 72px;
  padding: 24px 0;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #222222;
}
.con-item {
  height: 64px;
  padding: 21px 0;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #3c3c3c;
}
.item-center {
  position: absolute;
  left: 110px;
}
.update {
  position: absolute;
  left: 456px;
  color: #604bdc;
}
.update:hover {
  cursor: pointer;
}
.passwd {
  width: 400px;
  height: 86px;
  padding: 12px 16px;
}
.label {
  height: 20px;
  width: 300px;
  font-size: 14px;
  margin-left: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #666666;
}
.passwd input {
  width: 320px;
  height: 40px;
}
.passwd button {
  width: 320px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #fff;
  border-color: #d8d8d8;
  background: #d8d8d8;
}
.btn {
  width: 320px;
  height: 48px;
  padding: 0px 16px;
  margin-top: 16px;
}
.btn button {
  width: 320px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: #fff;
  border-color: #d8d8d8;
  background: #d8d8d8;
}
.btn .onedit {
  border-color: #604bdc;
  background: #604bdc;
}
</style>
